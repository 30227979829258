import React, { useState } from 'react';

const PrivacyTerms = () => {
  const [showPage, setShowPage] = useState(true);

  const handleClose = () => {
    setShowPage(false);
    window.close(); // 브라우저의 페이지를 닫습니다. 단, 일부 환경에서는 정상적으로 동작하지 않을 수 있습니다.
  };

  if (!showPage) {
    return null; // 페이지를 닫으면 아무것도 표시되지 않도록 합니다.
  }

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>개인정보 수집/이용에 관한 동의</h2>
      <pre style={styles.text}>
        Bitsoop auto trade system 은 「개인정보보호법」 제15조제1항제1호, 제17조제1항제1호, 제23조제1호, 제24조제1항 제1호에 따라{"\n"}
        아래와 같이 개인정보의 수집. 이용에 관하여 귀하의 동의를 얻고자 합니다.{"\n"}{"\n"}

        Bitsoop auto trade system 은 이용자의 사전 동의 없이는 이용자의 개인정보를 함부로 공개하지 않으며, 수집된 정보는 아래와 같이 이용하고 있습니다.{"\n"}
        이용자가 제공한 모든 정보는 아래의 목적에 필요한 용도 이외로는 사용되지 않으며 이용 목적이 변경될 시에는 이를 알리고 동의를 구할 것입니다.{"\n"}{"\n"}

        개인정보의 수집 및 이용 동의{"\n"}
        1. 개인정보의 수집 및 이용 목적{"\n"}
        가. 서비스 제공에 관한 업무 이행 - 자동매매프로그램 컨텐츠 제공{"\n"}
        나. 회원관리{"\n"}
        - 회원제 서비스 이용 및 제한적 본인 확인제에 따른 본인확인, 개인식별, 가입의사 확인, 가입 및 가입횟수 제한, 추후 법정 대리인 본인확인, 분쟁 조정을 위한 기록보존, 불만처리 등 민원처리, 공지사항 전달{"\n"}{"\n"}

        2. 수집하는 개인정보의 항목{"\n"}
        필수항목 : E-mail, 비밀번호, 핸드폰번호, 이메일, UID값, API, Secretkey, passphrase{"\n"}
        IP주소, 서비스 이용기록, 방문기록, 쿠키 등{"\n"}{"\n"}

        3.개인정보의 보유 및 이용기간{"\n"}
        Bitsoop auto trade system 은 원칙적으로 보유기간의 경과, 개인정보의 수집 및 이용목적의 달성 등 그 개인정보가 불필요하게 되었을 때에는 지체 없이 파기합니다. 다만, 다른 법령에 따라 보존하여야 하는 경우에는 그러하지 않을 수 있습니다. 불필요하게 되었을 때에는 지체 없이 해당 개인정보를 파기합니다.{"\n"}{"\n"}

        회원정보{"\n"}
        -탈퇴 후 지체없이 파기{"\n"}{"\n"}

        4. 동의거부권 및 불이익{"\n"}
        정보주체는 개인정보 수집에 동의를 거부할 권리가 있습니다. 다만, 필수 항목에 대한 동의를 거부할 시 저희가 제공하는 서비스를 이용할 수 없습니다.{"\n"}
      </pre>
      <button className='confirm-button' onClick={handleClose}>확인</button>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: 'black',
    color: 'white',
    padding: '40px',
    fontFamily: 'Arial, sans-serif',
    height: '100vh',
  },
  title: {
    color: 'white',
  },
  text: {
    whiteSpace: 'pre-wrap', // 유지하려면 주석처리
    wordWrap: 'break-word', // 유지하려면 주석처리
  }
};

export default PrivacyTerms;
