import React, { useEffect, useState } from "react";


export default function Signup(){

    const[email,setEmail] = useState('');
    const[pw,setPw] = useState('');
    const[confirmPw, setConfirmPw] = useState('');
    const[mobile, setMobile] = useState('');
    const[uid, setUid] = useState('');
    const[api, setApi] = useState('');
    const[secretkey, setSecretkey] = useState('');
    const[api_pw, setApi_pw] = useState('');
    const[nickname, setNickname] = useState('');
    const [homeconsent, setHomeconsent] = useState(false);
    const [consent, setConsent] = useState(false);

    const [showMessage, setShowMessage] = useState(false);

    const[emailValid, setEmailValid] = useState(false);
    const[pwValid, setPwValid] = useState(false);
    const[confirmPwValid, setConfirmPwValid] = useState('');
    const[mobileValid, setMobileValid] = useState('');
    const[uidValid, setUidValid] = useState('');
    const[apiValid, setApiValid] = useState('');
    const[secretkeyValid, setSecretkeyValid] = useState('');
    const[api_pwValid, setApi_pwValid] = useState('');
    const[nicknameValid, setNicknameValid] = useState('');

    const[notAllow, setNotAllow] = useState(true);

    useEffect(() => {
      // 여기에 통신 요청이 완료되면 알림 메시지를 자동으로 사라지게 하는 로직을 추가하세요.
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 5000); // 5초 후에 알림 메시지를 숨김
      return () => clearTimeout(timer); // 컴포넌트가 언마운트되면 타이머 해제
    }, [showMessage]); 

    
    const handleEmail = (e) => {
      const newEmail = e.target.value.trim();
      setEmail(newEmail);
      const regex = 
      /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
      if (regex.test(newEmail)) {
          setEmailValid(true);
        } 
      else {
          setEmailValid(false);
        }
      };
    const handlePw = (e) => {
      const newPw = e.target.value.trim();
      setPw(newPw);
        const regex =
          /^(?=.*[a-zA-z])(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+])(?!.*[^a-zA-z0-9$`~!@$!%*#^?&\\(\\)\-_=+]).{8,20}$/;
        if (regex.test(newPw)) {
          setPwValid(true);
        } 
      else {
          setPwValid(false);
        }
      };

    const handleConfirmPw = (e) => {
      const newConfirmPw = e.target.value.trim();
      setConfirmPw(newConfirmPw);
      const regex =
        /^(?=.*[a-zA-z])(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+])(?!.*[^a-zA-z0-9$`~!@$!%*#^?&\\(\\)\-_=+]).{8,20}$/;
      if (regex.test(newConfirmPw)) {
        setConfirmPwValid(true);
        } 
      else {
          setConfirmPwValid(false);
          }
      };
      
    const handleMobile = (e) => {
      const mobileValue = e.target.value.trim();
      setMobile(mobileValue);
      const regex = /^01([0|1|6|7|8|9]?)-?([0-9]{3,4})-?([0-9]{4})$/;
      if (regex.test(mobileValue)) {
        setMobileValid(true);
      } else {
        setMobileValid(false);
      }
    };
        

    const handleUid = (e) => {
          const newUid = e.target.value.trim();
          setUid(newUid);
          const regex = /^[0-9]{10}$/;
          if (regex.test(newUid)) {
            setUidValid(true);
          } else {
            setUidValid(false);
          }
        };

    
    const handleApi = (e) => {
      const newApi = e.target.value.trim();
      setApi(newApi);
      const regex = /^[a-zA-Z0-9_-]{10,70}$/;
      if (regex.test(newApi)) {
        setApiValid(true);
      } else {
        setApiValid(false);
      }
    };

    const handleSecretkey = (e) => {
      const newSecretkey = e.target.value.trim();
      setSecretkey(newSecretkey);
      const regex = /^[a-zA-Z0-9]{10,100}$/;
      if (regex.test(newSecretkey)) {
        setSecretkeyValid(true);
      } else {
        setSecretkeyValid(false);
      }
    };

    const handleApi_pw = (e) => {
      const newApi = e.target.value.trim();
      setApi_pw(newApi);
      const regex =
      /^[ㄱ-ㅎ가-힣a-zA-Z0-9]{8,16}$/;
      if (regex.test(newApi)) {
        setApi_pwValid(true);
      } else {
        setApi_pwValid(false);
      }
    };

    const handleNickname = (e) => {
      const newNickname = e.target.value.trim();
      setNickname(newNickname);
      const regex =
      /^[ㄱ-ㅎ가-힣a-zA-Z0-9]{2,16}$/;
      if (regex.test(newNickname)) {
        setNicknameValid(true);
      } else {
        setNicknameValid(false);
      }
    };

    const handleHomeconsent = () => setHomeconsent(!homeconsent);
    const handleConsent = () => setConsent(!consent);
    
    useEffect(() => {
        if(emailValid && pwValid && confirmPwValid && mobileValid && uidValid && apiValid && secretkeyValid && nicknameValid && api_pwValid && homeconsent && consent) {
          setNotAllow(false);
          return;
        }
        setNotAllow(true);
      }, [emailValid, pwValid, confirmPwValid, mobileValid, uidValid, apiValid, secretkeyValid, nicknameValid, api_pwValid, homeconsent, consent]);
    
    
    const onClickSaveFile = () => {

      setShowMessage(true);

      fetch('/signup/members', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email,
                pw,
                mobile,
                uid,
                api,
                secretkey,
                api_pw,
                nickname,
            }),
        })

        //에러 메시지 받아오는건 다음에 다시 하자!

        .then(response => {
          if (!response.ok) {
            console.log(response);
            return response.text().then(errorMessage => {
              // 서버로부터 에러 응답을 받은 경우
              throw new Error(errorMessage);
            });
          }
          return response.json();
        })
        .then(result => {
            // const result = JSON.parse(message)
            // 서버로부터 받은 데이터를 처리
            setShowMessage(true);
            console.log(result);
            alert(result.message);
            // 로그인 성공 후 리다이렉트할 URL
            if (result.ok === true){
              const redirectUrl = `/Login_ats`;
                // 페이지를 리다이렉트
              window.location.href = redirectUrl;
            };
        })
        .catch(error => {
            // 서버 오류 또는 요청이 실패한 경우
            console.error('오류 발생:', error);
            alert(error);
        });
      
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onClickSaveFile();
    }
  };

    return(
        <div className="page">
            <div className="titleWrap">
                Sign up page
            </div>

            <div className="contentWrap">
                <div className="inputTitle">E-mail address</div>
                <div className="inputWrap">
                    <input 
                    type='text'
                    className="input"
                    placeholder="test@gmail.com"
                    value={email}
                    onChange={handleEmail}
                    onKeyDown={handleKeyDown} />
                </div>
                <div className="errorMessageWrap">
                    {!emailValid && email.length > 0 && (
                        <div>Please enter a valid email format.</div>
                )}
            </div>

            <div style={{ marginTop: "26px"}} className="inputTitle">Password</div>
                <div className="inputWrap">
                    <input 
                    type='password'
                    className="input"
                    placeholder="At least 8 characters including English letters, numbers, special characters, and uppercase letters"
                    value={pw}
                    onChange={handlePw}
                    onKeyDown={handleKeyDown} />
                </div>
                <div className="errorMessageWrap">
                    {!pwValid && pw.length > 0 && (
                        <div>Please enter at least 8 characters including English letters, numbers, special characters, and uppercase letters.</div>
                    )}
            </div>

            {showMessage && (
              <div className="popupMessage">
                API와 Secretkey 값을 거래소에 확인 중입니다. 잠시만 기다리세요!
              </div>
            )}

            <div style={{ marginTop: "26px"}} className="inputTitle">Confirm password</div>
                <div className="inputWrap">
                    <input 
                    type='password'
                    className="input"
                    placeholder="At least 8 characters including English letters, numbers, and special characters"
                    value={confirmPw}
                    onChange={handleConfirmPw}
                    onKeyDown={handleKeyDown} />
                </div>
                <div className="errorMessageWrap">
                    {!confirmPwValid && confirmPw.length > 0 && (
                        <div>Please enter at least 8 characters including English letters, numbers, special characters, and uppercase letters</div>
                    )}
            </div>

            <div style={{ marginTop: "26px"}} className="inputTitle">Mobile Number</div>
                <div className="inputWrap">
                    <input 
                    type='text'
                    className="input"
                    placeholder="Please enter only numbers without '-'"
                    value={mobile}
                    onChange={handleMobile}
                    onKeyDown={handleKeyDown} />
                </div>
                <div className="errorMessageWrap">
                    {!mobileValid && mobile.length > 0 && (
                        <div>Please enter only numbers without '-'</div>
                    )}
            </div>

            <div style={{ marginTop: "26px"}} className="inputTitle">UID</div>
                <div className="inputWrap">
                    <input 
                    type='text'
                    className="input"
                    placeholder="Enter only 10 digits."
                    value={uid}
                    onChange={handleUid}
                    onKeyDown={handleKeyDown} />
                </div>
                <div className="errorMessageWrap">
                    {!uidValid && uid.length > 0 && (
                        <div>Please enter only 10 digits.</div>
                    )}
            </div>

            <div style={{ marginTop: "26px"}} className="inputTitle">API</div>
                <div className="inputWrap">
                    <input 
                    type='text'
                    className="input"
                    placeholder="Please enter the API value exactly(copy and paste)"
                    value={api}
                    onChange={handleApi}
                    onKeyDown={handleKeyDown} />
                </div>
                <div className="errorMessageWrap">
                    {!apiValid && api.length > 0 && (
                        <div>Please enter correct API inputs.</div>
                    )}
            </div>

            <div style={{ marginTop: "26px"}} className="inputTitle">SecretKey</div>
                <div className="inputWrap">
                    <input 
                    type='text'
                    className="input"
                    placeholder="Please enter the SecretKey value exactly(copy and paste)"
                    value={secretkey}
                    onChange={handleSecretkey}
                    onKeyDown={handleKeyDown} />
                </div>
                <div className="errorMessageWrap">
                    {!secretkeyValid && secretkey.length > 0 && (
                        <div>Please enter correct Secretkey inputs.</div>
                    )}
            </div>

            <div style={{ marginTop: "26px"}} className="inputTitle">API password</div>
                <div className="inputWrap">
                    <input 
                    type='text'
                    className="input"
                    placeholder="Please enter the API password value exactly(copy and paste)"
                    value={api_pw}
                    onChange={handleApi_pw}
                    onKeyDown={handleKeyDown} />
                </div>
                <div className="errorMessageWrap">
                    {!api_pwValid && api_pw.length > 0 && (
                        <div>Please enter correct API password inputs.</div>
                    )}
            </div>

            <div style={{ marginTop: "26px"}} className="inputTitle">Nickname</div>
                <div className="inputWrap">
                    <input 
                    type='text'
                    className="input"
                    placeholder="Enter at least 2 characters and no more than 8 characters."
                    value={nickname}
                    onChange={handleNickname}
                    onKeyDown={handleKeyDown} />
                </div>
                <div className="errorMessageWrap">
                    {!nicknameValid && nickname.length > 0 && (
                        <div>Please enter a valid nickname.</div>
                    )}
            </div>

            <div style={{ marginTop: "26px" }}>
            <label style={{ fontSize: "12px" }}>
                    <input 
                        type="checkbox"
                        checked={homeconsent}
                        onChange={handleHomeconsent}
                    />
                    Bitsoop auto trade system 사이트 이용약관에 동의합니다{' '}
                  <a href="/home-terms" target="_blank" rel="noopener noreferrer">(내용확인)</a>
                </label>
            </div>

            <div style={{ marginTop: "5px" }}>
                <label style={{ fontSize: "12px" }}>
                    <input 
                        type="checkbox"
                        checked={consent}
                        onChange={handleConsent}
                    />
                    개인정보 수집/이용에 동의합니다{' '}
                  <a href="/privacy-terms" target="_blank" rel="noopener noreferrer">(내용확인)</a>
                </label>
            </div>

            </div>

            {/* Add a marginBottom to create space */}
            <div style={{ marginBottom: "20px" }}></div>

            <div>              
                <button onClick={() => onClickSaveFile()} disabled={notAllow} className="bottomButton">
                Sign up
                </button>
            </div>
            <div className="signuplink">
                <a href="/">메인 페이지</a>
                <a href="/Login_ats">로그인 페이지</a>
            </div>
        </div>
    );
}