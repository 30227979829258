import React, { useEffect, useState } from "react";
import { useCookies } from 'react-cookie';


export default function SetPage(){

    const [cookies] = useCookies(['uuid']);

    // 쿠키에서 UUID를 이용하여 사용자 정보 가져오기
    const[uid,setUID] = useState(0);
    const[nickname,setNickname] = useState('');

    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
      if (!cookies.uuid) {
        alert('로그인 정보가 존재하지 않습니다. 로그인 페이지로 이동합니다.');
        window.location.href = `/Login_ats`;
        return;
      } else {
        const uuid = cookies.uuid;
        fetchUserInfo(uuid);
      }
    }, []);

    useEffect(() => {
      console.log('클라이언트 uuid', cookies.uuid);
    }, [cookies.uuid]);
  
    const fetchUserInfo = (uuid) => {
      fetch(`/SetPage/user?uuid=${uuid}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('서버 응답 실패');
          }
          return response.json();
        })
        .then(data => {
          const user = data[0];
          setUID(user.uid);
          setNickname(user.nickname);
          fetchLists(user.uid);
        })
        .catch(error => {
          alert('로그인 정보가 존재하지 않습니다. 로그인 페이지로 이동합니다.');
          window.location.href = `/Login_ats`;
          return;
        });
    };
    
    // selectedlevel, entry, waiting list 받아오기
    const fetchLists = (uid) => {
      fetch(`/startcheck/searchlevel?uid=${uid}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('서버 응답 실패');
          }
          return response.json();
        })
        .then(data => {
          if(data[0].super_user === "8"){
            setLevelCheck(data[0].super_user);  
          }else{
            setLevelCheck(data[0].selectedLevel);
          }
          console.log(setlevel);
        })
        .catch(error => {
          console.error('Entry List 가져오기 오류:', error);
          alert(error);
        });

      fetch(`/startcheck_leverage/list_entry?uid=${uid}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('서버 응답 실패');
          }
          return response.json();
        })
        .then(data => {
          updateList(data, e_items, setE_Items);
        })
        .catch(error => {
          console.error('Entry List 가져오기 오류:', error);
          alert(error);
        });
  
      fetch(`/startcheck_leverage/list_wait?uid=${uid}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('서버 응답 실패');
          }
          return response.json();
        })
        .then(data => {
          updateList(data, w_items, setW_Items);
        })
        .catch(error => {
          console.error('Waiting List 가져오기 오류:', error);
          alert(error);
        });
    };
  
    const updateList = (data, list, setList) => {
      for (let i = 0; i < data.length; i++) {
        if (!isItemInList(data[i].symbol, list)) {
          setList(prevList => [...prevList, data[i].symbol]);
        }
      }
    };

    const isItemInList = (item, list) => {
      return list.includes(item);
    };

    const[seed,setSeed] = useState('basic');

    const[symbol,setSymbol] = useState('default');

    const[buy_ratio,setBuyratio] = useState(0.1);
    const[margin,setMargin] = useState(0.1);

    const[leverage_info_1, setLeverage_1] = useState(10);
    const[leverage_info_2, setLeverage_2] = useState(10);
    const[leverage_info_3, setLeverage_3] = useState(10);
    const[leverage_info_4, setLeverage_4] = useState(10);
    const[leverage_info_5, setLeverage_5] = useState(10);
    const[leverage_info_6, setLeverage_6] = useState(10);
    const[leverage_info_7, setLeverage_7] = useState(10);
    const[leverage_info_8, setLeverage_8] = useState(10);
    const[leverage_info_9, setLeverage_9] = useState(10);
    const[leverage_info_10, setLeverage_10] = useState(10);
    const[leverage_info_11, setLeverage_11] = useState(10);
    const[leverage_info_12, setLeverage_12] = useState(10);

    const[entry_usdt_1, setEntryUsdt_1] = useState(10);
    const[entry_usdt_2, setEntryUsdt_2] = useState(10);
    const[entry_usdt_3, setEntryUsdt_3] = useState(10);
    const[entry_usdt_4, setEntryUsdt_4] = useState(10);
    const[entry_usdt_5, setEntryUsdt_5] = useState(10);
    const[entry_usdt_6, setEntryUsdt_6] = useState(10);
    const[entry_usdt_7, setEntryUsdt_7] = useState(10);
    const[entry_usdt_8, setEntryUsdt_8] = useState(10);
    const[entry_usdt_9, setEntryUsdt_9] = useState(10);
    const[entry_usdt_10, setEntryUsdt_10] = useState(10);
    const[entry_usdt_11, setEntryUsdt_11] = useState(10);
    const[entry_usdt_12, setEntryUsdt_12] = useState(10);
    
    const[growper_2, setGrowper_2] = useState(1);
    const[growper_3, setGrowper_3] = useState(1);
    const[growper_4, setGrowper_4] = useState(1);
    const[growper_5, setGrowper_5] = useState(1);
    const[growper_6, setGrowper_6] = useState(1);
    const[growper_7, setGrowper_7] = useState(1);
    const[growper_8, setGrowper_8] = useState(1);
    const[growper_9, setGrowper_9] = useState(1);
    const[growper_10, setGrowper_10] = useState(1);
    const[growper_11, setGrowper_11] = useState(1);
    const[growper_12, setGrowper_12] = useState(1);

    const[buyratioValid, setBuyratioValid] = useState(true);
    const[marginValid, setMarginValid] = useState(true);

    const[leverageValid_1,setLeverageValid_1] = useState(true);
    const[leverageValid_2,setLeverageValid_2] = useState(true);
    const[leverageValid_3,setLeverageValid_3] = useState(true);
    const[leverageValid_4,setLeverageValid_4] = useState(true);
    const[leverageValid_5,setLeverageValid_5] = useState(true);
    const[leverageValid_6,setLeverageValid_6] = useState(true);
    const[leverageValid_7,setLeverageValid_7] = useState(true);
    const[leverageValid_8,setLeverageValid_8] = useState(true);
    const[leverageValid_9,setLeverageValid_9] = useState(true);
    const[leverageValid_10,setLeverageValid_10] = useState(true);
    const[leverageValid_11,setLeverageValid_11] = useState(true);
    const[leverageValid_12,setLeverageValid_12] = useState(true);

    const[entryusdtValid_1,setEntryUsdtValid_1] = useState(true);
    const[entryusdtValid_2,setEntryUsdtValid_2] = useState(true);
    const[entryusdtValid_3,setEntryUsdtValid_3] = useState(true);
    const[entryusdtValid_4,setEntryUsdtValid_4] = useState(true);
    const[entryusdtValid_5,setEntryUsdtValid_5] = useState(true);
    const[entryusdtValid_6,setEntryUsdtValid_6] = useState(true);
    const[entryusdtValid_7,setEntryUsdtValid_7] = useState(true);
    const[entryusdtValid_8,setEntryUsdtValid_8] = useState(true);
    const[entryusdtValid_9,setEntryUsdtValid_9] = useState(true);
    const[entryusdtValid_10,setEntryUsdtValid_10] = useState(true);
    const[entryusdtValid_11,setEntryUsdtValid_11] = useState(true);
    const[entryusdtValid_12,setEntryUsdtValid_12] = useState(true);

    const[growperValid_2,setGrowperValid_2] = useState(true);
    const[growperValid_3,setGrowperValid_3] = useState(true);
    const[growperValid_4,setGrowperValid_4] = useState(true);
    const[growperValid_5,setGrowperValid_5] = useState(true);
    const[growperValid_6,setGrowperValid_6] = useState(true);
    const[growperValid_7,setGrowperValid_7] = useState(true);
    const[growperValid_8,setGrowperValid_8] = useState(true);
    const[growperValid_9,setGrowperValid_9] = useState(true);
    const[growperValid_10,setGrowperValid_10] = useState(true);
    const[growperValid_11,setGrowperValid_11] = useState(true);
    const[growperValid_12,setGrowperValid_12] = useState(true);

    const[trend,setTrend] = useState('trend');
    const[direction,setDirection] = useState('direction');
    const[entry_standard,setEntryStandard] = useState('trendstrength');

    const[setlevel, setLevelCheck] = useState(0);

    const[notAllow, setNotAllow] = useState(true);

    const[e_items, setE_Items] = useState([]);
    const[w_items, setW_Items] = useState([]);

    const handleCheckboxChange = (event) => {
      setIsChecked(event.target.checked);
    };

    const handleTrend = (e) => {
      setTrend(e.target.value);
        };

    const handleDirection = (e) => {
      setDirection(e.target.value);
        };
    
    const handleEntryStandard = (e) => {
      setEntryStandard(e.target.value);
      setDirection("twoway")
        };
    
    const[search,setSearch] = useState(false);

    const[trade_start,setTradeStart] = useState(false);

    
    const[benefit_stop,setBenefit_stop] = useState(false);
    const[benefit_cancel,setBenefit_cancel] = useState(false);

    const[currentCoin_stop,setCurrentCoin_stop] = useState(false);
    const[allCoin_stop,setAllCoin_stop] = useState(false);

    const[end,setEnd] = useState(false);
    
    // 유효성 검사 //// 유효성 검사 //// 유효성 검사 //// 유효성 검사 //// 유효성 검사 //// 유효성 검사 //
    const handleBuyRatio = (e) => {
      const newLinetrend = e.target.value.trim();
      setBuyratio(newLinetrend);
        const regex = 
        /^\d*\.?\d*$/;
        if (regex.test(newLinetrend)) {
          setBuyratioValid(true);
          } 
        else {
          setBuyratioValid(false);
          }
        };

    const handleMargin = (e) => {
      const newMargin = e.target.value.trim();
      setMargin(newMargin);
        const regex = 
        /^\d*\.?\d*$/;
        if (regex.test(newMargin)) {
          setMarginValid(true);
          } 
        else {
          setMarginValid(false);
          }
        };
    
    // 레버리지 유효성 검사 //// 레버리지 유효성 검사 //// 레버리지 유효성 검사 //
    const handleLeverage_1 = (e) => {
      const newLeverage = e.target.value.trim();
      setLeverage_1(newLeverage);
        const regex = 
        /^\d*$/;
        if (regex.test(newLeverage)) {
          setLeverageValid_1(true);
          } 
        else {
          setLeverageValid_1(false);
          }
        };
    const handleLeverage_2 = (e) => {
      const newLeverage = e.target.value.trim();
      setLeverage_2(newLeverage);
        const regex = 
        /^\d*$/;
        if (regex.test(newLeverage)) {
          setLeverageValid_2(true);
          } 
        else {
          setLeverageValid_2(false);
          }
        };
    const handleLeverage_3 = (e) => {
      const newLeverage = e.target.value.trim();
      setLeverage_3(newLeverage);
        const regex = 
        /^\d*$/;
        if (regex.test(newLeverage)) {
          setLeverageValid_3(true);
          } 
        else {
          setLeverageValid_3(false);
          }
        };
    const handleLeverage_4 = (e) => {
      const newLeverage = e.target.value.trim();
      setLeverage_4(newLeverage);
        const regex = 
        /^\d*$/;
        if (regex.test(newLeverage)) {
          setLeverageValid_4(true);
          } 
        else {
          setLeverageValid_4(false);
          }
        };
    const handleLeverage_5 = (e) => {
      const newLeverage = e.target.value.trim();
      setLeverage_5(newLeverage);
        const regex = 
        /^\d*$/;
        if (regex.test(newLeverage)) {
          setLeverageValid_5(true);
          } 
        else {
          setLeverageValid_5(false);
          }
        };
    const handleLeverage_6 = (e) => {
      const newLeverage = e.target.value.trim();
      setLeverage_6(newLeverage);
        const regex = 
        /^\d*$/;
        if (regex.test(newLeverage)) {
          setLeverageValid_6(true);
          } 
        else {
          setLeverageValid_6(false);
          }
        };
    const handleLeverage_7 = (e) => {
      const newLeverage = e.target.value.trim();
      setLeverage_7(newLeverage);
        const regex = 
        /^\d*$/;
        if (regex.test(newLeverage)) {
          setLeverageValid_7(true);
          } 
        else {
          setLeverageValid_7(false);
          }
        };
    const handleLeverage_8 = (e) => {
      const newLeverage = e.target.value.trim();
      setLeverage_8(newLeverage);
        const regex = 
        /^\d*$/;
        if (regex.test(newLeverage)) {
          setLeverageValid_8(true);
          } 
        else {
          setLeverageValid_8(false);
          }
        };
    const handleLeverage_9 = (e) => {
      const newLeverage = e.target.value.trim();
      setLeverage_9(newLeverage);
        const regex = 
        /^\d*$/;
        if (regex.test(newLeverage)) {
          setLeverageValid_9(true);
          } 
        else {
          setLeverageValid_9(false);
          }
        };
    const handleLeverage_10 = (e) => {
      const newLeverage = e.target.value.trim();
      setLeverage_10(newLeverage);
        const regex = 
        /^\d*$/;
        if (regex.test(newLeverage)) {
          setLeverageValid_10(true);
          } 
        else {
          setLeverageValid_10(false);
          }
        };
    const handleLeverage_11 = (e) => {
      const newLeverage = e.target.value.trim();
      setLeverage_11(newLeverage);
        const regex = 
        /^\d*$/;
        if (regex.test(newLeverage)) {
          setLeverageValid_11(true);
          } 
        else {
          setLeverageValid_11(false);
          }
        };
    const handleLeverage_12 = (e) => {
      const newLeverage = e.target.value.trim();
      setLeverage_12(newLeverage);
        const regex = 
        /^\d*$/;
        if (regex.test(newLeverage)) {
          setLeverageValid_12(true);
          } 
        else {
          setLeverageValid_12(false);
          }
        };
    
    // 진입 USDT 유효성 검사 //// 진입 USDT 유효성 검사 //// 진입 USDT 유효성 검사 //
    const handleEntryUsdt_1 = (e) => {
      const newEntryUsdt = e.target.value.trim();
      setEntryUsdt_1(newEntryUsdt);
        const regex = 
        /^\d*\.?\d*$/;
        if (regex.test(newEntryUsdt)) {
          setEntryUsdtValid_1(true);
          } 
        else {
          setEntryUsdtValid_1(false);
          }
        };
    const handleEntryUsdt_2 = (e) => {
      const newEntryUsdt = e.target.value.trim();
      setEntryUsdt_2(newEntryUsdt);
        const regex = 
        /^\d*\.?\d*$/;
        if (regex.test(newEntryUsdt)) {
          setEntryUsdtValid_2(true);
          } 
        else {
          setEntryUsdtValid_2(false);
          }
        };
    const handleEntryUsdt_3 = (e) => {
      const newEntryUsdt = e.target.value.trim();
      setEntryUsdt_3(newEntryUsdt);
        const regex = 
        /^\d*\.?\d*$/;
        if (regex.test(newEntryUsdt)) {
          setEntryUsdtValid_3(true);
          } 
        else {
          setEntryUsdtValid_3(false);
          }
        };
    const handleEntryUsdt_4 = (e) => {
      const newEntryUsdt = e.target.value.trim();
      setEntryUsdt_4(newEntryUsdt);
        const regex = 
        /^\d*\.?\d*$/;
        if (regex.test(newEntryUsdt)) {
          setEntryUsdtValid_4(true);
          } 
        else {
          setEntryUsdtValid_4(false);
          }
        };
    const handleEntryUsdt_5 = (e) => {
      const newEntryUsdt = e.target.value.trim();
      setEntryUsdt_5(newEntryUsdt);
        const regex = 
        /^\d*\.?\d*$/;
        if (regex.test(newEntryUsdt)) {
          setEntryUsdtValid_5(true);
          } 
        else {
          setEntryUsdtValid_5(false);
          }
        };
    const handleEntryUsdt_6 = (e) => {
      const newEntryUsdt = e.target.value.trim();
      setEntryUsdt_6(newEntryUsdt);
        const regex = 
        /^\d*\.?\d*$/;
        if (regex.test(newEntryUsdt)) {
          setEntryUsdtValid_6(true);
          } 
        else {
          setEntryUsdtValid_6(false);           
          }
        };
    const handleEntryUsdt_7 = (e) => {
      const newEntryUsdt = e.target.value.trim();
      setEntryUsdt_7(newEntryUsdt);
        const regex = 
        /^\d*\.?\d*$/;
        if (regex.test(newEntryUsdt)) {
          setEntryUsdtValid_7(true);
          } 
        else {
          setEntryUsdtValid_7(false);
          }
        };
    const handleEntryUsdt_8 = (e) => {
      const newEntryUsdt = e.target.value.trim();
      setEntryUsdt_8(newEntryUsdt);
        const regex = 
        /^\d*\.?\d*$/;
        if (regex.test(newEntryUsdt)) {
          setEntryUsdtValid_8(true);
          } 
        else {
          setEntryUsdtValid_8(false);
          }
        };
    const handleEntryUsdt_9 = (e) => {
      const newEntryUsdt = e.target.value.trim();
      setEntryUsdt_9(newEntryUsdt);
        const regex = 
        /^\d*\.?\d*$/;
        if (regex.test(newEntryUsdt)) {
          setEntryUsdtValid_9(true);
          } 
        else {
          setEntryUsdtValid_9(false);
          }
        };
    const handleEntryUsdt_10 = (e) => {
      const newEntryUsdt = e.target.value.trim();
      setEntryUsdt_10(newEntryUsdt);
        const regex = 
        /^\d*\.?\d*$/;
        if (regex.test(newEntryUsdt)) {
          setEntryUsdtValid_10(true);
          } 
        else {
          setEntryUsdtValid_10(false);
          }
        };
    const handleEntryUsdt_11 = (e) => {
      const newEntryUsdt = e.target.value.trim();
      setEntryUsdt_11(newEntryUsdt);
        const regex = 
        /^\d*\.?\d*$/;
        if (regex.test(newEntryUsdt)) {
          setEntryUsdtValid_11(true);
          } 
        else {
          setEntryUsdtValid_11(false);
          }
        };
    const handleEntryUsdt_12 = (e) => {
      const newEntryUsdt = e.target.value.trim();
      setEntryUsdt_12(newEntryUsdt);
        const regex = 
        /^\d*\.?\d*$/;
        if (regex.test(newEntryUsdt)) {
          setEntryUsdtValid_12(true);
          } 
        else {
          setEntryUsdtValid_12(false);
          }
        };

    // 진입간격 //// 진입간격 //// 진입간격 //// 진입간격 //// 진입간격 //
    const handleGrowper_2 = (e) => {
      const newGrowper = e.target.value.trim();
      setGrowper_2(newGrowper);
        const regex = 
        /^\d*\.?\d*$/;
        if (regex.test(newGrowper)) {
          setGrowperValid_2(true);
          } 
        else {
          setGrowperValid_2(false);
          }
        };
    const handleGrowper_3 = (e) => {
      const newGrowper = e.target.value.trim();
      setGrowper_3(newGrowper);
        const regex = 
        /^\d*\.?\d*$/;
        if (regex.test(newGrowper)) {
          setGrowperValid_3(true);
          } 
        else {
          setGrowperValid_3(false);
          }
        };
    const handleGrowper_4 = (e) => {
      const newGrowper = e.target.value.trim();
      setGrowper_4(newGrowper);
        const regex = 
        /^\d*\.?\d*$/;
        if (regex.test(newGrowper)) {
          setGrowperValid_4(true);
          } 
        else {
          setGrowperValid_4(false);
          }
        };
    const handleGrowper_5 = (e) => {
      const newGrowper = e.target.value.trim();
      setGrowper_5(newGrowper);
        const regex = 
        /^\d*\.?\d*$/;
        if (regex.test(newGrowper)) {
          setGrowperValid_5(true);
          } 
        else {
          setGrowperValid_5(false);
          }
        };
    const handleGrowper_6 = (e) => {
      const newGrowper = e.target.value.trim();
      setGrowper_6(newGrowper);
        const regex = 
        /^\d*\.?\d*$/;
        if (regex.test(newGrowper)) {
          setGrowperValid_6(true);
          } 
        else {
          setGrowperValid_6(false);
          }
        };
    const handleGrowper_7 = (e) => {
      const newGrowper = e.target.value.trim();
      setGrowper_7(newGrowper);
        const regex = 
        /^\d*\.?\d*$/;
        if (regex.test(newGrowper)) {
          setGrowperValid_7(true);
          } 
        else {
          setGrowperValid_7(false);
          }
        };
    const handleGrowper_8 = (e) => {
      const newGrowper = e.target.value.trim();
      setGrowper_8(newGrowper);
        const regex = 
        /^\d*\.?\d*$/;
        if (regex.test(newGrowper)) {
          setGrowperValid_8(true);
          } 
        else {
          setGrowperValid_8(false);
          }
        };
    const handleGrowper_9 = (e) => {
      const newGrowper = e.target.value.trim();
      setGrowper_9(newGrowper);
        const regex = 
        /^\d*\.?\d*$/;
        if (regex.test(newGrowper)) {
          setGrowperValid_9(true);
          } 
        else {
          setGrowperValid_9(false);
          }
        };
    const handleGrowper_10 = (e) => {
      const newGrowper = e.target.value.trim();
      setGrowper_10(newGrowper);
        const regex = 
        /^\d*\.?\d*$/;
        if (regex.test(newGrowper)) {
          setGrowperValid_10(true);
          } 
        else {
          setGrowperValid_10(false);
          }
        };
    const handleGrowper_11 = (e) => {
      const newGrowper = e.target.value.trim();
      setGrowper_11(newGrowper);
        const regex = 
        /^\d*\.?\d*$/;
        if (regex.test(newGrowper)) {
          setGrowperValid_11(true);
          } 
        else {
          setGrowperValid_11(false);
          }
        };
    const handleGrowper_12 = (e) => {
      const newGrowper = e.target.value.trim();
      setGrowper_12(newGrowper);
        const regex = 
        /^\d*\.?\d*$/;
        if (regex.test(newGrowper)) {
          setGrowperValid_12(true);
          } 
        else {
          setGrowperValid_12(false);
          }
        };

    const addListItem = (newItem, listSetter) => {
      listSetter(prevList => [...prevList, newItem]);
    };

    const removeListItem = (itemName, listSetter) => {
      listSetter(prevList => prevList.filter(item => item !== itemName));
    };
    
    const onClickCoin = (coin) => {
      setSymbol(coin);
      onClickSearch(coin);
    }

    useEffect(() => {

      if(buyratioValid && marginValid && leverageValid_1 && leverageValid_2 && leverageValid_3 && leverageValid_4 &&
        leverageValid_5 && leverageValid_6 && leverageValid_7 && leverageValid_8 && leverageValid_9 && leverageValid_10 &&
        leverageValid_11 && leverageValid_12 && entryusdtValid_1 && entryusdtValid_2 && entryusdtValid_3 && entryusdtValid_4 &&
        entryusdtValid_5 && entryusdtValid_6 && entryusdtValid_7 && entryusdtValid_8 && entryusdtValid_9 && entryusdtValid_10 && entryusdtValid_11 &&
        entryusdtValid_12 && growperValid_2 && growperValid_3 && growperValid_4 && growperValid_5 && growperValid_6 && growperValid_7 && growperValid_8 &&
        growperValid_9 && growperValid_10 && growperValid_11 && growperValid_12) {
        setNotAllow(false);
        return;
      }
      setNotAllow(true);
    }, [buyratioValid, marginValid, leverageValid_1, leverageValid_2, leverageValid_3, leverageValid_4,
      leverageValid_5, leverageValid_6, leverageValid_7, leverageValid_8, leverageValid_9, leverageValid_10,
      leverageValid_11, leverageValid_12, entryusdtValid_1, entryusdtValid_2, entryusdtValid_3, entryusdtValid_4,
      entryusdtValid_5, entryusdtValid_6, entryusdtValid_7, entryusdtValid_8, entryusdtValid_9, entryusdtValid_10, entryusdtValid_11,
      entryusdtValid_12, growperValid_2, growperValid_3, growperValid_4, growperValid_5, growperValid_6, growperValid_7, growperValid_8,
      growperValid_9, growperValid_10, growperValid_11, growperValid_12]);

    useEffect(() => {
        console.log(symbol);
    },[symbol]);

    const onClickSearch = (symbol) => {
      let urlName
      const str = symbol;
      console.log(str);
      const uidurl = uid;
      if(str === 'default'){
        urlName = 'default'
      }else{
        const index = str.indexOf('/');
        urlName = str.substring(0,index);
      }  
      fetch(`/setdata_leverage/${uidurl}/${urlName}/${setlevel}`)
      .then((response) => {
        if(!response.ok) {
          throw new Error('400 아니면 500 에러남');
        }
        return response.json()
      })
      .then((result) => {
        console.log('db에서 성공적으로 데이터 받아옴');
        setBuyratio(result[0].buy_ratio)
        setMargin(result[0].magin);

        setLeverage_1(result[0].leverage_info_1);
        setLeverage_2(result[0].leverage_info_2);
        setLeverage_3(result[0].leverage_info_3);
        setLeverage_4(result[0].leverage_info_4);
        setLeverage_5(result[0].leverage_info_5);
        setLeverage_6(result[0].leverage_info_6);
        setLeverage_7(result[0].leverage_info_7);
        setLeverage_8(result[0].leverage_info_8);
        setLeverage_9(result[0].leverage_info_9);
        setLeverage_10(result[0].leverage_info_10);
        setLeverage_11(result[0].leverage_info_11);
        setLeverage_12(result[0].leverage_info_12);

        setEntryUsdt_1(result[0].first_entry_usdt_1);
        setEntryUsdt_2(result[0].first_entry_usdt_2);
        setEntryUsdt_3(result[0].first_entry_usdt_3);
        setEntryUsdt_4(result[0].first_entry_usdt_4);
        setEntryUsdt_5(result[0].first_entry_usdt_5);
        setEntryUsdt_6(result[0].first_entry_usdt_6);
        setEntryUsdt_7(result[0].first_entry_usdt_7);
        setEntryUsdt_8(result[0].first_entry_usdt_8);
        setEntryUsdt_9(result[0].first_entry_usdt_9);
        setEntryUsdt_10(result[0].first_entry_usdt_10);
        setEntryUsdt_11(result[0].first_entry_usdt_11);
        setEntryUsdt_12(result[0].first_entry_usdt_12);

        setGrowper_2(result[0].growper_2);
        setGrowper_3(result[0].growper_3);
        setGrowper_4(result[0].growper_4);
        setGrowper_5(result[0].growper_5);
        setGrowper_6(result[0].growper_6);
        setGrowper_7(result[0].growper_7);
        setGrowper_8(result[0].growper_8);
        setGrowper_9(result[0].growper_9);
        setGrowper_10(result[0].growper_10);
        setGrowper_11(result[0].growper_11);
        setGrowper_12(result[0].growper_12);

        setTradeStart(result[0].trade_start);
        setTrend(result[0].trend);
        setDirection(result[0].direction)
        setEntryStandard(result[0].entry_standard)
        setBenefit_stop(result[0].current_symbol_stop);
        setBenefit_cancel(result[0].clean_up_profit_cancel);
        setCurrentCoin_stop(result[0].current_coin_clear);
        setAllCoin_stop(result[0].all_coin_clear);
        setEnd(result[0].end);

        const e_checklist = isItemInList(result[0].symbol, e_items);
        const w_checklist = isItemInList(result[0].symbol, w_items);
        
        if(str !== 'default'){
          result.forEach(item => {
            // console.log('item',item)
            if ((item.trade_start === 1 || item.trade_start === '1') && e_checklist === false) {
              addListItem(item.symbol, setE_Items);
            }
            if ((item.trade_start === 0 || item.trade_start === '0') && e_checklist === true) {
              removeListItem(item.symbol, setE_Items);
            }
          
            // Waiting coin 리스트 삽입 및 삭제
            if ((item.current_symbol_stop === 1 || item.current_symbol_stop === '1') && w_checklist === false) {
              addListItem(item.symbol, setW_Items);
            }
            if ((item.current_symbol_stop === 0 || item.current_symbol_stop === '0') && w_checklist === true) {
              removeListItem(item.symbol, setW_Items);
            }
          });
        };

        console.log('e_items',e_items);
        console.log('e_checklist',e_checklist);
      })
      .catch((error) => {
        console.log('에러남',error);
      })
    }

    const onClickStart = () => {

      if (symbol === "default") {
        alert('코인을 먼저 선택하세요!');
        return; // Stop execution if the condition is met
      }
      
      if (symbol === "BTC/USDT:USDT" && entry_usdt_1 < 7) {
        alert('BTC/USDT:USDT는 최초 진입금액은 최소 7 USDT 이상이어야 합니다.');
        return; // Stop execution if the condition is met
      }

      if (symbol === "ETH/USDT:USDT" && entry_usdt_1 < 4) {
        alert('ETH/USDT:USDT는 최초 진입금액은 최소 4 USDT 이상이어야 합니다.');
        return; // Stop execution if the condition is met
      }

      if (symbol === "ETC/USDT:USDT" && entry_usdt_1 < 3) {
        alert('ETC/USDT:USDT는 최초 진입금액은 최소 3 USDT 이상이어야 합니다..');
        return; // Stop execution if the condition is met
      }

      if (symbol === "LINK/USDT:USDT" && entry_usdt_1 < 2) {
        alert('LINK/USDT:USDT는 최초 진입금액은 최소 2 USDT 이상이어야 합니다..');
        return; // Stop execution if the condition is met
      }

      if (symbol === "SOL/USDT:USDT" && entry_usdt_1 < 2) {
        alert('SOL/USDT:USDT는 최초 진입금액은 최소 2 USDT 이상이어야 합니다..');
        return; // Stop execution if the condition is met
      }

      if (symbol === "SOL/USDT:USDT" && entry_usdt_1 < 2) {
        alert('SOL/USDT:USDT는 최초 진입금액은 최소 2 USDT 이상이어야 합니다..');
        return; // Stop execution if the condition is met
      }

      if (margin > 50) {
        alert('목표수익률은 50 이상 설정되지 않습니다. 목표 수익률이 너무 크면 프로그램이 정상 작동되지 않습니다. 수익률을 재설정하세요!');
        return; // Stop execution if the condition is met
      }

      const confirmed = window.confirm(symbol + '의 매매를 시작하시겠습니까?');

      if (confirmed) {
        console.log('스타트 버튼 클릭')
        fetch('/start_leverage', {
          method : 'POST',
          headers: {
            'Content-Type' : 'application/json',
          },
          body: JSON.stringify({
            uid,
            symbol,
            buy_ratio,
            margin,
            leverage_info_1,
            leverage_info_2,
            leverage_info_3,
            leverage_info_4,
            leverage_info_5,
            leverage_info_6,
            leverage_info_7,
            leverage_info_8,
            leverage_info_9, 
            leverage_info_10,
            leverage_info_11,
            leverage_info_12,
            entry_usdt_1,
            entry_usdt_2,
            entry_usdt_3,
            entry_usdt_4,
            entry_usdt_5,
            entry_usdt_6,
            entry_usdt_7,
            entry_usdt_8,
            entry_usdt_9,
            entry_usdt_10,
            entry_usdt_11,
            entry_usdt_12,
            growper_2,
            growper_3,
            growper_4,
            growper_5,
            growper_6,
            growper_7,
            growper_8,
            growper_9,
            growper_10,
            growper_11,
            growper_12,
            trade_start,
            trend,
            direction
            }),
         });
       };
      console.log(entry_usdt_1)
      console.log(entry_usdt_2)
      console.log(growper_2)
      onClickSearch(symbol);
    };

    const autoTradeStart = (seed) => {

      if (seed === "basic") {
        alert('자동 매매 시드를 먼저 선택하세요!');
        return; // Stop execution if the condition is met
      }

      const uidurl = uid;

      alert('시드 자동 셋팅 매매는 안정적인 매매법의 추천값이지 절대값은 아님을 알려드립니다. 시장의 변동성에 따라 손실이 발생할 수 있음을 미리 인지하시고 본인의 판단에 따라 적절히 활용하세요');

      const confirmed = window.confirm(seed + ' USDT 기준의 자동매매를 시작하시겠습니까?');

      if (confirmed) {
        console.log(uidurl)
        fetch(`/auto_start_leverage/${uidurl}/${seed}`)
        .then((response) => {
          if(!response.ok) {
            throw new Error('400 아니면 500 에러남');
          }
          return response.json()
        })
        .then((result) => {
          console.log('auto_start에서 받아온 데이터',result);
        })
        .catch((error) => {
          console.log('에러남',error);
        })
        window.location.reload()
        // onClickSearch('default');
      };
      };

    // 익절 후 정리
    const onClickBenefit_stop = (symbol) => {
      const confirmed = window.confirm('Do you want to clean up the selected coin after profiting?');

      if (confirmed) {
        setBenefit_stop(true);
        let urlName
        const str = symbol;
        console.log(str);
        const uidurl = uid;
        if(str === 'default'){
          urlName = 'default'
        }else{
          const index = str.indexOf('/');
          urlName = str.substring(0,index);
        }
        fetch(`/setdata_benefit_leverage/${uidurl}/${urlName}/${setlevel}`)
        .then((response) => {
          if(!response.ok) {
            throw new Error('400 아니면 500 에러남');
          }
          return response.json()
        })
        .then((result) => {
          console.log('benefit에서 받아온 데이터',result);
        })
        .catch((error) => {
          console.log('에러남',error);
          })
        };
      onClickSearch(symbol);
    }

    // 익절 후 정리 취소 
    const onClickBenefit_cancel = (symbol) => {
      const confirmed = window.confirm('Do you want to cancel clearing after profiting the selected coins?');

      if (confirmed) {
        setBenefit_cancel(true);
        let urlName
        const str = symbol;
        console.log(str);
        const uidurl = uid;
        if(str === 'default'){
          urlName = 'default'
        }else{
          const index = str.indexOf('/');
          urlName = str.substring(0,index);
        }
        fetch(`/setdata_benefit_cancel_leverage/${uidurl}/${urlName}/${setlevel}`)
        .then((response) => {
          if(!response.ok) {
            throw new Error('400 아니면 500 에러남');
          }
          return response.json()
        })
        .then((result) => {
          console.log('benefit_cancel에서 받아온 데이터',result);
        })
        .catch((error) => {
          console.log('에러남',error);
        })
      };
      onClickSearch(symbol);
    }

    // 현재 코인 정리 
    const onClickCurrentCoin_stop = (symbol) => {
      const confirmed = window.confirm('Would you like to liquidate the selected coins right away?');

      if (confirmed) {
        setCurrentCoin_stop(true);
        const str = symbol;
        console.log(str);
        const uidurl = uid;
        const index = str.indexOf('/');
        const urlName = str.substring(0,index);
        fetch(`/setdata_current_coin_clear_leverage/${uidurl}/${urlName}`)
        .then((response) => {
          if(!response.ok) {
            throw new Error('400 아니면 500 에러남');
          }
          return response.json()
        })
        .then((result) => {
          console.log('current_stop에서 받아온 데이터',result);
        })
        .catch((error) => {
          console.log('에러남',error);
        })
      };
      onClickSearch(symbol);
    }

    //진입중인 모든 코인 정리
    const onClickAllCoin_stop = () => {
      const confirmed = window.confirm('Do you really want to liquidate all coins that are currently entering the market?');

      if (confirmed) {
        setAllCoin_stop(true);
        const str = symbol;
        console.log(str);
        const uidurl = uid;
        const index = str.indexOf('/');
        const urlName = str.substring(0,index);
        fetch(`/setdata_all_coin_clear_leverage/${uidurl}/${urlName}`)
        .then((response) => {
          if(!response.ok) {
            throw new Error('400 아니면 500 에러남');
          }
          return response.json()
        })
        .then((result) => {
          console.log('current_stop에서 받아온 데이터',result);
        })
        .catch((error) => {
          console.log('에러남',error);
        })
      };
      onClickSearch(symbol);
    }

    //로그아웃
    const onClickEnd = () => {
        const uuid = cookies.uuid; // cookies에서 uuid를 얻어옴
        if (!uuid) {
          alert('로그인 정보가 존재하지 않습니다.');
          return;
        }
        // 로그아웃 요청을 서버로 보냅니다.
        fetch('/logout', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ userId: uuid })
        })
        .then(response => {
          if (!response.ok) {
            throw new Error('로그아웃 요청 실패');
          }
          console.log('로그아웃 성공');
          alert('Logout is successful.');
          window.location.href = '/';
        })
        .catch(error => {
          console.error('로그아웃 요청 중 오류 발생:', error);
        });
      // UUID를 서버로 보내는 예시 코드

    }

    return (
        <div className="page_sp_leverage">
          <div className="title_top_sp">
            <div></div>
            <div className="titleWrap_sp_lever">
            Bitsoop auto trade system (Leverage version)</div>
            <div className="inputTitle_uid_lever">
                <span>UID : {uid}</span>
                <span>{nickname}님 환영합니다</span>
            </div>
          </div>

          <div className="line_top"></div>

          <div className="centered">
            <div className="inputTitle_sp_price_leverage">Auto setting
              <input type="checkbox" onChange={handleCheckboxChange}/>
              <select className="select_price_leverage" disabled={!isChecked} value = {seed} onChange={(e) => {setSeed(e.target.value)}}>
                <option value="basic">Set seed</option>
                <option value="1,000">1,000</option>
                <option value="2,000">2,000</option>
                <option value="3,000">3,000</option>
                <option value="4,000">4,000</option>
                <option value="5,000">5,000</option>
                <option value="6,000">6,000</option>
                <option value="7,000">7,000</option>
                <option value="8,000">8,000</option>
                <option value="9,000">9,000</option>
                <option value="10,000">10,000</option>
                <option value="11,000">11,000</option>
                <option value="12,000">12,000</option>
                <option value="13,000">13,000</option>
                <option value="14,000">14,000</option>
                <option value="15,000">15,000</option>
                <option value="16,000">16,000</option>
                <option value="17,000">17,000</option>
                <option value="18,000">18,000</option>
                <option value="19,000">19,000</option>
                <option value="20,000">20,000</option>
              </select>
              USDT
            </div>
            <button onClick={() => autoTradeStart(seed)} disabled={!isChecked} className="button_price_select_leverage">
                자동 매매 시작
            </button>
          </div>

          <div className="line_top"></div>

            <div className="container">
              <div className="column_leverage">
              {/* {setlevel <= 7 && (
                  <div className="popupMessage_setpage">
                    <p>포지션 진입 코인과 진입은 
                      셋팅된 레벨에 따라 다르게 진입합니다.<br/><br/>
                      코인익절 후 정리 : Entry coins 의 리스트 코인을 waiting cois 리스트로 이동합니다.<br/><br/>
                      취 소 : Waiting coins의 리스트 코인을 Entry coins 리스트로 이동합니다.<br/><br/>
                      Entry coins의 리스트에 있는 코인은 익절 후 지속적으로 재진입합니다.<br/><br/>
                      Waiting coins의 리스트에 있는 코인은 익절 후 진입 중지합니다.
                    </p>
                  </div>
              )} */}

            <div className="button_benefit_leverage">
              <select className="usdt_select_lv" value = {symbol} onChange={(e) => {setSymbol(e.target.value); onClickSearch(e.target.value);}}>
                <option value="default">USDT Select</option>
                <option value="BTC/USDT:USDT">BTC/USDT</option>
                <option value="ETH/USDT:USDT">ETH/USDT</option>
                <option value="XRP/USDT:USDT">XRP/USDT</option>
                <option value="BNB/USDT:USDT">BNB/USDT</option>
                <option value="SOL/USDT:USDT">SOL/USDT</option>
              </select>
            <button onClick={() => onClickSearch(symbol)} disabled={!symbol} className="button_benefit_c_lever">
                Search
            </button>

            </div>
            <div className="contentWrap_sp">

            <div className="line"></div>

            <div className="center_text_lever">Basic setting</div>
              <div class="leverage_container">
                <div class="leverage_item">Entry trend strength(%)</div>
                <input 
                type="number"
                class="leverage_input_basic"
                value={buy_ratio}
                onChange={handleBuyRatio} />
                <div class="leverage_item">Target rate of return(%)</div>
                <input 
                type="number" 
                class="leverage_input_basic"
                value={margin}
                onChange={handleMargin} />
              </div>
            
            {/* 레버리지 셋팅  레버리지 셋팅 레버리지 셋팅 레버리지 셋팅 레버리지 셋팅*/}
            <div className="line"></div>
              <div class="leverage_container">
                <div class="leverage_item">1st set</div>
                <div class="leverage_item">Leverage</div>
                <input 
                type="number"
                step="1"
                class="leverage_input"
                value={leverage_info_1}
                onChange={handleLeverage_1} />
                <div class="leverage_item">Entry USDT</div>
                <input
                type="number"
                class="leverage_input"
                value={entry_usdt_1}
                onChange={handleEntryUsdt_1} />
                <div class="leverage_item leverage_hidden">Entry interval(%)</div>
                <input type="text" class="leverage_input leverage_hidden" />
              </div>

              <div class="leverage_container">
                <div class="leverage_item">2nd set</div>
                <div class="leverage_item">Leverage</div>
                <input
                type="number"
                step="1"
                class="leverage_input"
                value={leverage_info_2}
                onChange={handleLeverage_2} />
                <div class="leverage_item">Entry USDT</div>
                <input
                type="number"
                class="leverage_input"
                value={entry_usdt_2}
                onChange={handleEntryUsdt_2} />
                <div class="leverage_item">Entry interval(%)</div>
                <input
                type="number"
                class="leverage_input"
                value={growper_2}
                onChange={handleGrowper_2} />
              </div>

              <div class="leverage_container">
                <div class="leverage_item">3rd set</div>
                <div class="leverage_item">Leverage</div>
                <input
                type="number"
                step="1"
                class="leverage_input"
                value={leverage_info_3}
                onChange={handleLeverage_3} />
                <div class="leverage_item">Entry USDT</div>
                <input
                type="number"
                class="leverage_input"
                value={entry_usdt_3}
                onChange={handleEntryUsdt_3} />
                <div class="leverage_item">Entry interval(%)</div>
                <input
                type="number"
                class="leverage_input"
                value={growper_3}
                onChange={handleGrowper_3} />
              </div>

              <div class="leverage_container">
                <div class="leverage_item">4th set</div>
                <div class="leverage_item">Leverage</div>
                <input
                type="number"
                step="1"
                class="leverage_input"
                value={leverage_info_4}
                onChange={handleLeverage_4} />
                <div class="leverage_item">Entry USDT</div>
                <input
                type="number"
                class="leverage_input"
                value={entry_usdt_4}
                onChange={handleEntryUsdt_4} />
                <div class="leverage_item">Entry interval(%)</div>
                <input
                type="number"
                class="leverage_input"
                value={growper_4}
                onChange={handleGrowper_4} />
              </div>

              <div class="leverage_container">
                <div class="leverage_item">5th set</div>
                <div class="leverage_item">Leverage</div>
                <input
                type="number"
                step="1"
                class="leverage_input"
                value={leverage_info_5}
                onChange={handleLeverage_5} />
                <div class="leverage_item">Entry USDT</div>
                <input
                type="number"
                class="leverage_input"
                value={entry_usdt_5}
                onChange={handleEntryUsdt_5} />
                <div class="leverage_item">Entry interval(%)</div>
                <input
                type="number"
                class="leverage_input"
                value={growper_5}
                onChange={handleGrowper_5} />
              </div>

              <div class="leverage_container">
                <div class="leverage_item">6th set</div>
                <div class="leverage_item">Leverage</div>
                <input
                type="number"
                step="1"
                class="leverage_input"
                value={leverage_info_6}
                onChange={handleLeverage_6} />
                <div class="leverage_item">Entry USDT</div>
                <input
                type="number"
                class="leverage_input"
                value={entry_usdt_6}
                onChange={handleEntryUsdt_6} />
                <div class="leverage_item">Entry interval(%)</div>
                <input
                type="number"
                class="leverage_input"
                value={growper_6}
                onChange={handleGrowper_6} />
              </div>

              <div class="leverage_container">
                <div class="leverage_item">7th set</div>
                <div class="leverage_item">Leverage</div>
                <input
                type="number"
                step="1"
                class="leverage_input"
                value={leverage_info_7}
                onChange={handleLeverage_7} />
                <div class="leverage_item">Entry USDT</div>
                <input
                type="number"
                class="leverage_input"
                value={entry_usdt_7}
                onChange={handleEntryUsdt_7} />
                <div class="leverage_item">Entry interval(%)</div>
                <input
                type="number"
                class="leverage_input"
                value={growper_7}
                onChange={handleGrowper_7} />
              </div>

              <div class="leverage_container">
                <div class="leverage_item">8th set</div>
                <div class="leverage_item">Leverage</div>
                <input
                type="number"
                step="1"
                class="leverage_input"
                value={leverage_info_8}
                onChange={handleLeverage_8} />
                <div class="leverage_item">Entry USDT</div>
                <input
                type="number"
                class="leverage_input"
                value={entry_usdt_8}
                onChange={handleEntryUsdt_8} />
                <div class="leverage_item">Entry interval(%)</div>
                <input
                type="number"
                class="leverage_input"
                value={growper_8}
                onChange={handleGrowper_8} />
              </div>

              <div class="leverage_container">
                <div class="leverage_item">9th set</div>
                <div class="leverage_item">Leverage</div>
                <input
                type="number"
                step="1"
                class="leverage_input"
                value={leverage_info_9}
                onChange={handleLeverage_9} />
                <div class="leverage_item">Entry USDT</div>
                <input
                type="number"
                class="leverage_input"
                value={entry_usdt_9}
                onChange={handleEntryUsdt_9} />
                <div class="leverage_item">Entry interval(%)</div>
                <input
                type="number"
                class="leverage_input"
                value={growper_9}
                onChange={handleGrowper_9} />
              </div>

              <div class="leverage_container">
                <div class="leverage_item">10th set</div>
                <div class="leverage_item">Leverage</div>
                <input
                type="number"
                step="1"
                class="leverage_input"
                value={leverage_info_10}
                onChange={handleLeverage_10} />
                <div class="leverage_item">Entry USDT</div>
                <input
                type="number"
                class="leverage_input"
                value={entry_usdt_10}
                onChange={handleEntryUsdt_10} />
                <div class="leverage_item">Entry interval(%)</div>
                <input
                type="number"
                class="leverage_input"
                value={growper_10}
                onChange={handleGrowper_10} />
              </div>

              <div class="leverage_container">
                <div class="leverage_item">11th set</div>
                <div class="leverage_item">Leverage</div>
                <input
                type="number"
                step="1"
                class="leverage_input"
                value={leverage_info_11}
                onChange={handleLeverage_11} />
                <div class="leverage_item">Entry USDT</div>
                <input
                type="number"
                class="leverage_input"
                value={entry_usdt_11}
                onChange={handleEntryUsdt_11} />
                <div class="leverage_item">Entry interval(%)</div>
                <input
                type="number"
                class="leverage_input"
                value={growper_11}
                onChange={handleGrowper_11} />
              </div>

              <div class="leverage_container">
                <div class="leverage_item">12th set</div>
                <div class="leverage_item">Leverage</div>
                <input
                type="number"
                step="1"
                class="leverage_input"
                value={leverage_info_12}
                onChange={handleLeverage_12} />
                <div class="leverage_item">Entry USDT</div>
                <input
                type="number"
                class="leverage_input"
                value={entry_usdt_12}
                onChange={handleEntryUsdt_12} />
                <div class="leverage_item">Entry interval(%)</div>
                <input
                type="number"
                class="leverage_input"
                value={growper_12}
                onChange={handleGrowper_12} />
              </div>

            <div className="line"></div>
            
            </div>

            <div className="button_benefit_leverage">
                <select className="trend_select_leverage" value={trend} onChange={handleTrend}>
                <option value="trends">Trend</option>
                <option value="countertrend">Countertrend</option>
                </select>
                <select style={{marginLeft:"auto"}} className="trend_select_leverage" disabled={entry_standard === 'webhook'} value={direction} onChange={handleDirection}>
                <option value="twoway">Twoway</option>
                <option value="long">Long</option>
                <option value="short">Short</option>
                </select>
            </div>

            <div className="button_benefit_leverage">
                <button onClick={() => onClickStart()} disabled={trade_start === 1 ||  benefit_stop === 1 || trade_start === '1' ||  benefit_stop === '1' || setlevel <= 7} className="bottomButton_sp_lever">
                Trading start
                </button>
            </div>

            <div className="button_benefit_leverage">
                <button onClick={() => onClickBenefit_stop(symbol)} disabled={benefit_stop === 1 || trade_start === 0 || benefit_stop === '1' || trade_start === '0'} className="button_benefit_c_lever">
                코인익절 후 정리
                </button>
                <button onClick={() => onClickBenefit_cancel(symbol)} disabled={benefit_stop === '0' || benefit_stop === 0} className="button_benefit_c_lever">
                취 소
                </button>
            </div>

            <div className="button_benefit_leverage">
                <button onClick={() => onClickCurrentCoin_stop(symbol)} disabled={(symbol === 'default' && trade_start === 0 &&  benefit_stop === 0) || (symbol === 'default' && trade_start === '0' &&  benefit_stop === '0') || setlevel <= 7} className="button_benefit_c_lever">
                현재 코인 정리
                </button>
                <button onClick={() => onClickAllCoin_stop()} disabled={symbol === 'default' && setlevel <= 7} className="button_benefit_c_lever">
                모든 코인 정리
                </button>
            </div>

            <div className="button_benefit_leverage">
                <button onClick={() => onClickEnd()} disabled={notAllow} className="bottomButton_sp_lever">
                로그 아웃
                </button>
             </div>
          </div>


          <div className="column_leverage">
              <div className="list_box_title_lever">Entry coins</div>
              <ul className="list_box_lever">
                {e_items.map((item, index) => (
                  <li
                    key={index}
                    className="list_item"
                    onClick={() => onClickCoin(item)}
                  >
                    {item}
                  </li>
                ))}
              </ul>
          </div>

          <div className="column_leverage">
            <div className="list_box_title_lever">Waiting coins</div>
              <ul className="list_box_lever">
                {w_items.map((item, index) => (
                  <li
                    key={index}
                    className="list_item"
                    onClick={() => onClickCoin(item)}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
    );
}