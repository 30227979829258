import React, { useEffect, useState } from "react";
import { useCookies } from 'react-cookie';


export default function SelectPage(){

    const [cookies] = useCookies(['uuid']);

    // 쿠키에서 UUID를 이용하여 사용자 정보 가져오기
    const[uid,setUID] = useState(0);
    const[nickname,setNickname] = useState('');

    useEffect(() => {
      if (!cookies.uuid) {
        alert('로그인 정보가 존재하지 않습니다. 로그인 페이지로 이동합니다.');
        window.location.href = `/Login_ats`;
        return;
      } else {
        const uuid = cookies.uuid;
        fetchUserInfo(uuid);
      }
    }, []);

    useEffect(() => {
      console.log('클라이언트 uuid', cookies.uuid);
    }, [cookies.uuid]);
  
    const fetchUserInfo = (uuid) => {
      fetch(`/SetPage/user?uuid=${uuid}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('서버 응답 실패');
          }
          return response.json();
        })
        .then(data => {
          const user = data[0];
          setUID(user.uid);
          setNickname(user.nickname);
        })
        .catch(error => {
          alert('로그인 정보가 존재하지 않습니다. 로그인 페이지로 이동합니다.');
          window.location.href = `/Login_ats`;
          return;
        });
    };
    
    console.log(uid);

    // Web version select
    const onWeb_version = () => {
        fetch(`/webversion_selected?uid=${uid}`)
        .then((response) => {
        if(!response.ok) {
            throw new Error('400 아니면 500 에러남');
        }
        return response.json()
        })
        .then((result) => {
          alert('Move to web version page');
          console.log('onweb_DB에서 받아온 데이터',result);
          window.location.href = `/SetPage`;
        })
        .catch((error) => {
        console.log('에러남',error);
        })
    };

    // Hook version select
    const onHook_version = () => {
        fetch(`/hookversion_selected?uid=${uid}`)
        .then((response) => {
        if(!response.ok) {
            throw new Error('400 아니면 500 에러남');
        }
        return response.json()
        })
        .then((result) => {
          console.log(result)
          if(result['message'] === 'ok'){
            alert('Move to Hook version page');
            console.log('onhook_DB에서 받아온 데이터',result);
            window.location.href = `/SetPage_hook`;
          } else {
            alert(result['message'])
          }
        })
        .catch((error) => {
        console.log('에러남',error);
        })
    };

    // Hook version select
    const onLeverage_version = () => {
      fetch(`/leverageversion_selected?uid=${uid}`)
      .then((response) => {
      if(!response.ok) {
          throw new Error('400 아니면 500 에러남');
      }
      return response.json()
      })
      .then((result) => {
        console.log(result)
        if(result['message'] === 'ok'){
          alert('Move to Leverage version page');
          window.location.href = `/SetPage_Leverage`;
        } else {
          alert(result['message'])
        }
      })
      .catch((error) => {
      console.log('에러남',error);
      })
  };


    return (
      <div className="container_select">
        <header className="header_select">
          Bitsoop auto trade system
        </header>
        <div className="button-container_select">
            <span>UID : {uid}</span>
            <span>{nickname}님 환영합니다</span>
            Select automatic trading program version
          <button onClick={() => onWeb_version()} className="button_select">Web version</button>
          {/* <button onClick={() => onHook_version()} className="button_select">Hook version</button> */}
          <button onClick={() => onLeverage_version()} className="button_select">Leverage version</button>
        </div>
      </div>
    );
  }
  